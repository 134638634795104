import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="成果が上がる理由"
      pagedesc="イチモンが制作する採用サイトがなぜ成果を出せるのか、ご説明いたします。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <div className="p-content">
        <div className="m-mv mv">
          <div className="mv__img-wrap">
            <Img fluid={data.key01.childImageSharp.fluid} alt="メインビジュアル画像" style={{ height: "100%", width: "100%" }} />
            <h2 className="mv__hdg">成果が上がる理由</h2>
          </div>
        </div>
        <div className="p-head head l-wrap">
          <h3 className="head__hdg--01">reasons</h3>
          <h4 className="head__desc--01">成果が上がる理由</h4>
        </div>

        <div className="p-representation representation l-wrap">
          <ul className="representation__list">
            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">01</span>
                <span className="deno">&#x2f;04</span>
              </div>
              <div className="representation__body">
                <h3 className="representation__hdg">
                  データに基づいた、中小企業のための採用手法の提案
                </h3>
                <p className="representation__desc">
                  「応募がない」「採用にかける予算がない」「知識を持った人材が社内にいない」「ノウハウがない」<br/>
                  中小企業における採用活動には多くの問題があります。採用サイトを構築し、運用することは、中小企業の採用活動を支える大きな武器になりえます。<br/>
                  念入りなヒアリングで、「採用したい人物像」「今の採用活動の課題」「採用活動の運用方法」を分析、検討し効果的な採用すき
                </p>
              </div>
            </li>

            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">02</span>
                <span className="deno">&#x2f;04</span>
              </div>

              <div className="representation__body">
                <h3 className="representation__hdg">
                  「使いやすい、わかりやすい」ウェブサイトを制作
                </h3>
                <p className="representation__desc">
                  応募を検討しているユーザがウェブサイトに求めていることを一言でいうなら「知りたい情報が簡単に知れること」です。<br/>
                  ユーザにとってストレスなく情報を知ることができるのは、企業を知ってもらい、応募してもらうために必須です。<br/>
                  念入りなヒアリングにより、「使いやすい、わかりやすい」ウェブサイトを制作します。
                </p>
              </div>
            </li>

            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">03</span>
                <span className="deno">&#x2f;04</span>
              </div>

              <div className="representation__body">
                <h3 className="representation__hdg">
                  「よりわかりやすい伝え方」を綿密なリサーチで
                </h3>
                <p className="representation__desc">
                  ５G技術がいよいよ導入され、情報発信のあり方が大きく変わろうとしています。<br/>
                  特に若い世代では、情報は「読む」ものから「観る」ものに変わっています。<br/>
                  Youtubeや動画メディアを活用し、求職者に伝わる情報発信のノウハウもお伝えします。
                </p>
              </div>
            </li>

            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">04</span>
                <span className="deno">&#x2f;04</span>
              </div>

              <div className="representation__body">
                <h3 className="representation__hdg">
                  サイト運用、SNS活用、広告出稿サポートまで
                </h3>
                <p className="representation__desc">
                  求職者が「新鮮で」「リアルな」「わかりやすい」企業の情報を求める以上、採用サイトは日々の更新が不可欠です。<br/>
                  しかし、運用には手間と人的コストがかかり、更新・運用活動が滞ってしまいがちです。<br/>
                  「理想的な人材の採用」という結果に必要なさまざまなアクションをお手伝いできます。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "reasons_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`